@font-face {
	font-family: 'Voltaire';
	font-style: normal;
	font-weight: 400;
	src: url(/fonts/voltaire.woff2) format('woff2');
}
@font-face{
	font-family:'Catamaran';
	src:url(/fonts/catamaran400.woff);
	font-weight:normal;
	font-style:normal;
}
@font-face{
	font-family:'Catamaran';
	src:url(/fonts/catamaran600.woff);
	font-weight:bold;
	font-style:normal;
}

/**************** general ****************/

$sand:#C09D8E;
$violet:#732F5A;
$blue_snooker:#555C9C;
$blue_grey:#1C3D47;
$blue_deep:#231A79;

$clair:#9f9b96;
$fonce:#464b4f;
$bleu:#245165;
$beige:#7b7471;

$moyen:#646667;


$over_bleu:#27aae4;
$orange:#ff7422;
$mouf:#dc8a5a;
$plouf:#c58057;

$text_high:$mouf;

$background:$moyen;
$background_page:rgba($fonce,0.9);
$text:#ddd;
$titles:$clair;
$text_menu_on:$text_high;
$text_menu_off:$plouf;

$font_titles:Voltaire;
$font_titles_weight:normal;
$font_text:Catamaran;
$font_menu:Catamaran;

:root {
	font-size: Min(16px,calc(13px + .5vw));
}

::selection {
	color:$fonce;
	background:rgba($mouf,0.99);
}
::-moz-selection {
	color:$fonce;
	background:$mouf;
}

* {
	font-family:$font_text, sans-serif;
	font-size:1rem;
	text-align:left;
	color:$text;
	margin:0;
	padding:0;
	border:0;
}
html {
	//height:100%;
	margin:0;
	padding:0;
	background:$background url(/img/tissu.jpg) no-repeat center center fixed;
	background-size:cover;
}
body {
	display: flex;
	justify-content: center;
}
a, .link {
	color:$mouf;
	text-decoration:none;
	cursor:pointer;
}
a:hover, .link:hover {
	color:#fff;
}
body > div:not(#chtulu) { // page container
	box-sizing: border-box;
	position: relative;
	margin:40px;
	text-align:left;
	padding:15px 30px;
	background-color:$background_page;
	z-index: 2;
	flex-basis: 670px;
}

h1 {
	color:$titles;
	font-family:$font_titles, sans-serif;
	font-weight:$font_titles_weight;
	font-size:2rem;
	margin:15px 0;
}
h2 {
	color:$titles;
	font-family:$font_titles, sans-serif;
	font-weight:$font_titles_weight;
	font-size:1.6rem;
	margin-bottom:22px;
}
h3 {
	color:$titles;
	font-family:$font_text, sans-serif;
	font-size:1.4rem;
	margin-bottom:8px;
}
p {
	margin-bottom:15px;
	text-align:justify;
}
li {
	list-style:none;
	margin-bottom:15px;
}
li:before {
	content: "\2022";
	opacity:0.3;
	position:absolute;
	margin-left:-17px;
}
ul {
	padding-left:17px;
}
main {
	line-height:22px;
}
em {
	font-weight:bold;
	color:$mouf;
}

#chtulu {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
	/*opacity:0.15;*/
	& canvas {
		z-index:1;
	}
}

/**************** header ****************/

.title {
	color:$titles;
	font-family:$font_titles, sans-serif;
	font-weight:$font_titles_weight;
	font-size:32px;
	margin:10px 0 20px 0;
}
a.title:hover {
	color:#ff9900;
}


/**************** footer ****************/

footer {
	margin:25px 0 0 0;
	font-size:12px;
	color:#aaa;
	text-align:right;
	* {
		font-size:12px;
	}
}


/**************** nav ****************/

$menu_shadow:rgba(0,0,0,0.2);
$menu_light:rgba(255,255,255,0.1);

nav {
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	margin-bottom:20px;
	div, a {
		flex-shrink: 1;
		font-family:$font_menu, sans-serif;
		font-size:.8rem;
		font-weight:bold;
		text-transform:uppercase;
		letter-spacing:0.13em;
		height:18px;
		line-height:18px;
		padding:10px 16px 7px 16px;
		border:1px solid;
		border-radius:3px;
		margin-bottom:10px;
	}
	a {
		background: linear-gradient(rgba(127,127,127,0.1),rgba(0,0,0,0.1));
		color:$text_menu_off;
		border-top-color:$menu_light;
		border-right-color:$menu_shadow;
		border-bottom-color:$menu_shadow;
		border-left-color:$menu_light;
		box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
	}
	a:hover, div {
		background: linear-gradient(rgba(255,255,255,0.1),rgba(127,127,127,0.1));
		color:$text_menu_on;
	}
	div {
		border-top-color:$menu_shadow;
		border-right-color:$menu_light;
		border-bottom-color:$menu_light;
		border-left-color:$menu_shadow;
		margin-top:1px;
		line-height:18px;
		box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.04) inset;
	}
}


/**************** index ****************/

.photo {
	margin-bottom:15px;
	width: 100%;
}


/**************** illustrations ****************/

#illustrations section {
	display:flex;
	flex-wrap:wrap;
	a {
		overflow:hidden;
		/*max-width:122px;
		min-width: 80px;*/
		/*height:122px;*/
		display: block;
		flex: 1 0 122px;
		img {
			width:100%;
			height:100%;
			display:block;
			-moz-transition-duration:0.3s;
			-o-transition-duration:0.3s;
			-webkit-transition-duration:0.3s;
			transition-duration:0.3s;
		}
		&:hover img {
			-moz-transform:scale(2, 2);
			-ms-transform:scale(2, 2);
			-webkit-transform:scale(2, 2);
			transform:scale(2, 2);
		}
	}
}

@media (max-width: 670px) {
	body > div:not(#chtulu) {
		margin: 0;
	}
}
@media (max-width: 610px) {
	header {
		margin-left: -10px;
		#logo {
			margin-left: 10px;
		}
		nav > * {
			flex-grow: 1;
			flex-basis: 0;
			margin-left: 10px;
			//width: 50%;
		}
	}
	nav::after {
		content: '';
		width: 100%;
		order: 1;
		display: block;
	}
	nav > *:nth-child(n + 3) {
		order: 2;
	}
}
@media (max-width: 350px) {
	nav {
		flex-direction: column;
	}
}